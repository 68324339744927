import * as React from "react";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import { useIntl } from "gatsby-plugin-intl";
import styled, { ThemeProvider } from "styled-components";
import { Reset } from "styled-reset";

import { ENGLISH_HOMEPAGE, FRENCH_HOMEPAGE } from ".";
import { getPageTitle } from "../Locales";
import { theme } from "../theme/theme";
import FontFaces from "../theme/fonts/font-faces";
import GlobalStyle from "../theme/global";
import { Header } from "../components/header";
import { Footer, StyledLink } from "../components/footer";
import { HalfCenteredRow } from "../components/common/layout";
import { formatBodyRaw } from "../utils/format-body-raw";
import { ContentfulPage } from "../utils/contentful";

const TermsOfServicePage = ({ data }) => {
  const termsOfServicesPage: ContentfulPage =
    formatTermsOfServicesQueryResult(data);
  const intl = useIntl();
  return (
    <ThemeProvider theme={theme}>
      <Reset />
      <GlobalStyle />
      <main>
        <Helmet>
          <title>{getPageTitle(termsOfServicesPage.title)}</title>
          <style type="text/css">{FontFaces}</style>
          <link
            rel="alternate"
            hrefLang="en-gb"
            href={ENGLISH_HOMEPAGE + "/tos"}
          />
          <link
            rel="alternate"
            hrefLang="en-us"
            href={ENGLISH_HOMEPAGE + "/tos-us"}
          />
          <link
            rel="alternate"
            hrefLang="en"
            href={ENGLISH_HOMEPAGE + "/tos-us"}
          />
          <link rel="alternate" hrefLang="fr" href={FRENCH_HOMEPAGE + "/tos"} />
          <link
            rel="alternate"
            hrefLang="x-default"
            href={ENGLISH_HOMEPAGE + "/tos-us"}
          />
          <meta
            name="description"
            content="What if all your purchases became cheaper, without any effort? Coupons, price tracking, cash back"
            key="description"
          />
        </Helmet>
        <Header />
        <Wrapper>
          {intl.locale === "en" ? (
            <Heading>
              <StyledLink to={"/tos"}>{`View ${intl.formatMessage({
                id: "home.footer.links.tos",
              })} (GB)`}</StyledLink>
            </Heading>
          ) : null}
          <HalfCenteredRow>
            <Heading className="heading">{termsOfServicesPage.title}</Heading>
            {formatBodyRaw(termsOfServicesPage.body.raw)}
          </HalfCenteredRow>
        </Wrapper>
        <Footer />
      </main>
    </ThemeProvider>
  );
};

export default TermsOfServicePage;

export const termsOfServicesQuery = graphql`
  query USTermsOfServicesQuery($language: String) {
    allContentfulPage(
      filter: {
        contentful_id: { in: ["3QkcdW5hxOKsUbk618p70q"] }
        node_locale: { eq: $language }
      }
    ) {
      nodes {
        title
        body {
          raw
        }
      }
    }
  }
`;

interface TermsOfServicesQueryResponse {
  allContentfulPage: {
    nodes: ContentfulPage[];
  };
}

function formatTermsOfServicesQueryResult(
  queryResult: TermsOfServicesQueryResponse
): ContentfulPage {
  return queryResult.allContentfulPage.nodes[0];
}

const Wrapper = styled.section`
  min-height: 100vh;
  box-sizing: border-box;
  padding: 6.25em 0;
`;

const Heading = styled.h1`
  text-align: center;
  margin-bottom: 1em;
`;
